import React from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import BrandGuidelinesNav from "../../components/brand-guidelines/brand-guidelines-nav";
import CustomLocalizedLink from "../../components/locales/custom-localized-link";

const IndexPage = () => {

  const {t} = useTranslation();

  return (
      <Layout>
        <Seo
            title={t('brand_guidelines_trade_title')}
            description={t('des_brand_guidelines_trade')}
        />
        <div className="brand-guidelines toc">
          <section className={'brand-guidelines-header'}>
            <div className="container">
              <h1 className={'headline'}><Trans>bg_headline</Trans></h1>
              <div className="section-delimeter"></div>
              <BrandGuidelinesNav selected={'trademark'}/>
              <h2><Trans>DIGILOCK TRADEMARK USAGE GUIDELINES</Trans></h2>
            </div>
          </section>
          <section className="hero">
            <div className="container">
              <p>
                Security People, Inc. dba Digilock (“Digilock”) owns numerous trademarks, service marks, trade dress,
                and logos (“Marks”), which are Digilock’s intellectual property and are highly valued assets. These
                Marks, whether registered or unregistered, must be used in accordance with these Guidelines in all
                written and electronic materials, documents, and communications.
              </p>
              <p>
                These Guidelines apply to authorized resellers of Digilock products and those who have a written
                agreement with Digilock that contains a license or permission (“Partners”). Outside of certain narrow
                legal exceptions, others may not use any of the Marks. Partners may use the Marks to reference
                Digilock’s goods and services, so long as they follow these Guidelines. Digilock retains the right to
                modify or cancel the license if a Partner fails to follow these Guidelines or any specific guidelines
                set forth in the agreement. If the agreement includes specific guidelines that are different from or
                inconsistent with these Guidelines, the agreement will take precedence.
              </p>
              <p>
                All usage of Digilock Marks is subject to the approval of Digilock. Digilock may, at its sole
                discretion, revise and update these Guidelines.
              </p>
              <h3>
                List of Digilock Marks
              </h3>
              <p>
                The below list contains Digilock’s registered and unregistered Marks that may be used by our Partners.
                This list may evolve over time and be updated without notice. This may include new rights based on
                usage, new applications, and current applications maturing into registrations. The list is not
                comprehensive of all Marks owned or claimed by Digilock, and the omission of a Mark from the list is not
                a waiver of any rights of Digilock in or to such Mark, but simply an indication that Partners are not
                authorized to use such omitted Marks.
              </p>
              <h3>
                Word Marks
              </h3>
              <table>
                <tr>
                  <td>DIGILOCK®</td>
                  <td>SECURITY SIMPLIFIED®</td>
                  <td>DIGILINK®</td>
                </tr>
                <tr>
                  <td>CURVE™</td>
                  <td>VERSA™</td>
                  <td>CLEO™</td>
                </tr>
                <tr>
                  <td>AXIS™</td>
                  <td>CUE®</td>
                  <td>ORBIT®</td>
                </tr>
                <tr>
                  <td>RANGE™</td>
                  <td>ASPIRE®</td>
                  <td>SOLA®</td>
                </tr>
                <tr>
                  <td>PIVOT™</td>
                  <td>ALTA®</td>
                  <td>THE COMBINATION REIMAGINED™</td>
                </tr>
                <tr>
                  <td>NEXTLOCK®</td>
                  <td>NUMERIS®</td>
                  <td>CELARE®</td>
                </tr>
                <tr>
                  <td>NEXTLOCKER®</td>
                  <td>LOCKUP®</td>
                  <td>CORE™</td>
                </tr>
                <tr>
                  <td>JUICEBAR®</td>
                  <td>PACKAGEHOLD®</td>
                  <td>&nbsp;</td>
                </tr>
              </table>
              <h3>Registered Trade Dress</h3>
              <StaticImage
                  src="./images/curve.png"
                  loading={'lazy'}
                  width={176}
                  height={244}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Digilock lock"
              />
              <h3 className={'no-pad'}>
                Referring to Digilock Products & Services
              </h3>
              <p>
                Partners shall only use the Marks for products and services that they are actively promoting and selling.  When referring to Digilock products or services, use the Mark associated with that product or service and make sure that such references: (i) are truthful, fair, and not misleading; and (ii) comply with these Guidelines. The Marks should never be used in a way that could cause anyone to wrongly believe that your business, products, or services are associated with, offered, endorsed, or sponsored by Digilock or any of its affiliates.
              </p>
              <p>
                Specifically, ensure that you:
                <ul>
                  <li>Use the appropriate trademark symbol and acknowledgment of Digilock’s ownership of the particular Mark;</li>
                  <li>Use the Mark as a singular adjective with a generic following term;</li>
                  <li>Do not incorporate Marks into your own internet domain names, trademarks, service marks, logos, or company names; and</li>
                  <li>Do not adopt marks or logos that imitate, play on, refer to, or are confusingly similar to any of the Marks.</li>
                </ul>
              </p>
              <h3>
                Trademark Notices
              </h3>
              <p>
                Properly designate the status of the Marks by using the correct trademark symbol (superscript ® or TM) reflecting Digilock’s ownership of particular Marks as above.
              </p>
              <p>
                You must properly designate the Marks (with either ® or ™) at the first or most prominent use where the Marks appear in your document, publication, website, or elsewhere (for example, in a title, heading, or sub-heading). Subsequent references to the same Mark may omit the notice symbol.
              </p>
              <h3>
                Trademark Usage
              </h3>
              <p>
                The Marks are singular adjectives, not verbs, and should never be made possessive.  Use the Marks only as adjectives followed by the appropriate generic product or service noun describing the relevant product or service. Examples of appropriate generic terms include: electronic lock, electronic locking device, software, platform, technology, or application.  Examples of proper usage include:
              </p>
              <p>
                <ul>
                  <li>Aspire® electronic locks</li>
                  <li>DigiLink® application</li>
                  <li>Core™ lockers</li>
                </ul>
              </p>
              <p>
                <strong>Never refer to the electronic locks themselves as “Digilocks” or “a Digilock.”</strong>  For example, the statements that “Digilocks are the best locks” and “You should buy a Digilock” are improper uses of the Mark.  Instead, say, “Digilock electronic locks are the best locks” or “You should buy a Digilock electronic lock.”
              </p>
              <p>
                The Marks should be used with initial caps, such as Digilock® and The Combination Reimagined™. The one exception is DigiLink®, with its interior capital L.
              </p>
              <h3>
                References to Digilock the Company
              </h3>
              <p>
                Digilock is a ‘dba’ of Security People, Inc., and the company refers to itself as Digilock.  The rules are slightly different when using the term Digilock to refer to our company, rather than as a brand of product.  Company names are proper nouns; they don’t need a generic term to follow, and they can be used in the possessive form. The ® symbol should not accompany references to Digilock as a company. Example:
                <blockquote>
                  Correct: Digilock offers the best electronic locks on the market. <br/>
                  Incorrect: Digilock® offers the best electronic locks on the market.
                </blockquote>
              </p>
              <h3>
                Ownership Assertion
              </h3>
              <p>
                You may not assert any right over any Mark or variation thereof.  For example, do not seek to obtain or claim trademark protection for any Mark or variant. Do not incorporate a Mark or variant into your own product or service names, trademarks, logos, social media handles, or company names. Do not use a Mark or variant in a domain name and do not register for domains that include a Mark or variant.
              </p>
              <h3>
                No Trademark Bidding
              </h3>
              <p>
                You may not bid on a Mark, or any variant thereof, as a keyword on any search engine, or use a Mark, or any variant thereof, in any form of paid advertising including, but not limited to, paid social and display advertisements.
              </p>
              <h3>
                No Tarnishment
              </h3>
              <p>
                You may not use a Mark in a disparaging, defamatory, libelous, or degrading manner or any other manner so as to bring ill will or disrepute to the product or service associated with the Mark.
              </p>
            </div>
          </section>
          <section className="bottom-nav">
            <div className="container">
              <div className="section-delimeter"></div>
              <CustomLocalizedLink label={t('bg_nav_bg')+' &gt;'} goto={'/brand-guidelines/'} cls={'bg-footer-nav'} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
